<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.cfg.platform||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.city" title="区域" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.isvip" title="用户类型" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{$userType[row.cfg.isvip]||"全部"}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="detailGet(row)">抽奖次数</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.cfg.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型" prop="isvip">
					<el-radio-group v-model="editDialogFormData.cfg.isvip">
						<el-radio label="0">全部</el-radio>
						<el-radio v-for="(item,k) of $userType" :key="k" :label="k">{{item}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="区域" prop="city">
					<el-select v-model="editDialogFormData.cfg.city" placeholder="请选择" style="width:100%">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="达成条件" prop="">
					<el-select placeholder="请选择" :value="editDialogFormData.cfg.finishnum==0?'1':'2'" style="width:100%" @change="changeFinish">
						<el-option label="单次" value="1"></el-option>
						<el-option label="多次" value="2"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="editDialogFormData.cfg.finishnum > 0">
					<el-form-item label="达成限制">
						<el-input-number type="number" :min="0" :max="100" :step="1" :precision="0" v-model="editDialogFormData.cfg.finishnum" style="width: 108px;" class="input" size="small" placeholder="次数">
						</el-input-number> 次/天
					</el-form-item>
					<el-form-item label="">
						<el-switch v-model="editDialogFormData.cfg.finishtp" active-text="按时长" inactive-text="按电量" active-value="1" inactive-value="0" />
					</el-form-item>
					<el-form-item v-if="editDialogFormData.cfg.finishtp == '1'" label="时长配置" prop="cfg">
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加档位</el-button><span style="color: red;">档位必须从大到小</span><br/>
						<div v-for="(item,idx) in editDialogFormData.cfg.time" v-bind:key="idx">
							大于等于<el-input-number type="number" :min="1" :max="600" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.time[idx][0]" style="width: 108px;" class="input" size="small" placeholder="分钟">
							</el-input-number>分 获得
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.time[idx][1]" style="width: 108px;" class="input" size="small" placeholder="次数">
							</el-input-number>次抽奖机会
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)">删除</el-button> 
						</div>
					</el-form-item>
					<el-form-item v-else label="电量配置" prop="cfg">
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加档位</el-button><span style="color: red;">档位必须从大到小</span><br/>
						<div v-for="(item,idx) in editDialogFormData.cfg.electric" v-bind:key="idx">
							大于等于<el-input-number type="number" :min="1" :max="600" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.electric[idx][0]" style="width: 108px;" class="input" size="small" placeholder="分钟">
							</el-input-number>度 获得
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.electric[idx][1]" style="width: 108px;" class="input" size="small" placeholder="次数">
							</el-input-number>次抽奖机会
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)">删除</el-button> 
						</div>
					</el-form-item>				
				</template>
				<el-form-item label="奖项配置" prop="cfg">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addReword">增加奖项</el-button> 按顺时针排序，所有概率相加等于1000
					<div v-for="(item,idx) in editDialogFormData.cfg.reword" v-bind:key="idx">
						奖项{{ idx+1 }} 
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addRewordItem(idx)">增加奖励</el-button>
						<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delReword(idx)">删除</el-button> 
						<div style="padding-left: 30px;">
							<div style="display:flex;align-items: center;">
								前端展示icon<upload style="display: flex;margin: 0 10px;" type="image" imgWidth="80" imgHeight="80" :fileList="editDialogFormData.cfg.reword[idx].file" @changeFile="changeFile(idx, $event)"></upload>
								概率 <el-input-number type="number" :min="1" :max="1000" :step="1" :precision="0"
									v-model="editDialogFormData.cfg.reword[idx].pro" style="width: 108px;" class="input" size="small" placeholder="概率">
								</el-input-number>
							</div>
							<div v-for="(itm,idx2) in item.list" v-bind:key="idx2">
								获得
								<el-select v-model="editDialogFormData.cfg.reword[idx].list[idx2].type" style="width: 110px;" size="small" @change="itemTypeChange(idx,idx2)">
									<el-option label="优惠券" :value="0"></el-option>
									<el-option label="电卡" :value="1"></el-option>
								</el-select>
								<el-select v-if="editDialogFormData.cfg.reword[idx].list[idx2].type==0" v-model="editDialogFormData.cfg.reword[idx].list[idx2].id" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择奖品">
									<el-option label="VIP" value="VIP"></el-option>
									<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
									</el-option>
								</el-select> 
								<el-select v-if="editDialogFormData.cfg.reword[idx].list[idx2].type==1" v-model="editDialogFormData.cfg.reword[idx].list[idx2].id" style="width: 130px;" class="select" size="small" placeholder="请选择奖品">
									<el-option v-for="item in cardList" :key="item.card_id" :label="item.card_name" :value="item.card_id">
									</el-option>
								</el-select>
								数量
								<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
									v-model="editDialogFormData.cfg.reword[idx].list[idx2].num" style="width: 108px;" class="input" size="small" placeholder="数量">
								</el-input-number>
								<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delRewordItem(idx,idx2)"></el-button> 							
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" active-value="1" inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'market-activitycommon-lottery',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '抽奖活动',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				couponList: [], //优惠券列表
				cardList:[], //电卡列表
				selecttime: {},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("platform" in this.editDialogFormData.cfg)) return callback(new Error('请选择生效平台'));
							callback();
						}
					}],
					isvip: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("isvip" in this.editDialogFormData.cfg)) return callback(new Error('请选择用户类型'));
							callback();
						}
					}],
					city: [{
						required: true,
						message: '请选择区域',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("city" in this.editDialogFormData.cfg)) return callback(new Error('请选择区域'));
							callback();
						}
					}],
					day_num: [{
						required: true,
						message: '请输入条件限制',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.$api.Market.GetCouponList(params, {}).then((res)=>{
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;				
			});
			this.$api.Market.GetCardList(params, {}).then((res)=>{
				this.cardList = res.data;				
			});
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 2,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				for(let v of res.data){
					v.btime = v.btime.substr(0,10);
					v.etime = v.etime.substr(0,10);
					v.cfg = JSON.parse(v.cfg);
					for(let r=0;r<v.cfg.reword.length;r++){
						if(!v.cfg.reword[r].pro){
							let obj = {file:v.cfg.reword[r][0],pro:v.cfg.reword[r][1],list:[]};
							for(let i=2;i<v.cfg.reword[r].length;i+=2){
								obj.list.push({type:0,id:v.cfg.reword[r][i],num:v.cfg.reword[r][i+1]});
							}
							v.cfg.reword[r] = obj;
						}
					}
				} 
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {cfg:{finishtp:'0',finishnum:0,electric:[],time:[],reword:[]}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				row.status = row.status.toString();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({"tp":2}, this.editDialogFormData)
						}
						params.form.cfg = JSON.stringify(params.form.cfg);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ActivityCommon.edit(params);
						}else{
							await this.$api.Market.ActivityCommon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 返券记录
			detail(row) {
				this.$router.push({
					name: "market-activitycommon-log",
					query: {id:row.id},
				});
			},			
			// 返券记录
			detailGet(row) {
				this.$router.push({
					name: "market-activitycommon-finish",
					query: {id:row.id},
				});
			},
			addCfg(){ //添加挡位配置
				if(this.editDialogFormData.cfg.finishtp == '0'){
					this.editDialogFormData.cfg.electric.push([0,1]);
				}else{
					this.editDialogFormData.cfg.time.push([0,1]);
				}
			},
			onItemDel(idx){ //删除挡位配置
				if(this.editDialogFormData.cfg.finishtp == '0'){
					this.editDialogFormData.cfg.electric.splice(idx, 1);
				}else{
					this.editDialogFormData.cfg.time.splice(idx, 1);
				}
			},
			changeFinish(e){ //修改达成类型
				if(e == 2){
					this.editDialogFormData.cfg.finishnum = 1;
				}else{
					this.editDialogFormData.cfg.finishnum = 0;
				}
			},
			itemTypeChange(idx, ci){ //奖励类型修改
				this.editDialogFormData.cfg.reword[idx].list[ci].id = '';
			},
			addReword(){ //增加奖励
				this.editDialogFormData.cfg.reword.push({file:[],pro:100,list:[]});
			},
			delReword(idx){ //增加奖励
				this.editDialogFormData.cfg.reword.splice(idx, 1);
			},
			addRewordItem(idx){ //增加奖励元素
				this.editDialogFormData.cfg.reword[idx].list.push({type:0,id:'',num:0});
			},
			delRewordItem(idx, ii){ //删除奖励元素
				this.editDialogFormData.cfg.reword[idx].list.splice(ii, 1);
			},
			changeFile(idx, fileInfo){ //上传的文件
				console.log("changeFile", idx, fileInfo);
				this.editDialogFormData.cfg.reword[idx].file = fileInfo;
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
